


























































































import {
  BRow,
  BCardText,
  BFormCheckbox,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton
} from "bootstrap-vue";
import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {isEqual} from "lodash";

@Component({
  components: {
    BRow,
    BCol,
    BCardText,
    BFormCheckbox,
    BForm,
    BFormInput,
    BFormGroup,
    BButton
  }
})
export default class ScopeSelectorScope extends Vue {
  @Prop({
    default: () => {
      return [];
    }, required: false
  })
  selectedScopes: string[]

  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  item: MaintenanceAPI.Platforms.ScopeGroupItem;

  @Prop({
    default: () => {
      return false;
    }, required: false
  })
  editable: boolean

  @Prop({
    default: () => {
      return false;
    }, required: false
  })
  editing: boolean

  @Prop({
    default: () => {
      return null;
    }, required: false
  })
  path: string

  editingItem?: MaintenanceAPI.Platforms.ScopeGroupItem = null;

  @Watch("editing", {immediate: true})
  watchEditing() {
    this.$set(this, "editingItem", JSON.parse(JSON.stringify(this.item)))
  }

  @Watch("editingItem", {deep: true})
  watchEditingItem() {
    if (this.editingItem == null) return;
    if (!this.hasBeenEdited) return;

    let newPath = this.item.path.split(":");
    newPath[newPath.length - 1] = this.editingItem.key;
    this.editingItem.path = newPath.join(":");

    this.$emit("set", {
      oldKey: this.item.key,
      path: this.editingItem.path,
      value: this.editingItem
    })
  }

  get hasBeenEdited() {
    return !isEqual(this.item, this.editingItem)
  }

  async select($event) {
   this.$emit("select", {path: this.item.path, remove: !$event})
  }

  get selectValue() {
    if(this.RoleCheck([this.item.path])) return true;
    return (this.selectedScopes.find(x=>x==this.item.path))
  }

  set selectValue($event) {
    //this.$emit("select", {path: this.item.path, remove: !$event})
  }
}





























































import { Vue, Component, Prop } from 'vue-property-decorator'
import {BButton, BCard, BCardTitle, BNavItem, BTab, BTabs} from "bootstrap-vue";
import ScopeSelectorScope
  from "@/apps/maintenance-api/pages/components/ScopeSelector/components/ScopeSelectorScope.vue";
import {VForm} from "@/global";

@Component({
  components: {
    ScopeSelectorScope,
    BCard,
    BCardTitle,
    BButton,
    BTabs,
    BTab,
    BNavItem,
    ScopeSelectorScopeGroup
  }
})
export default class ScopeSelectorScopeGroup extends Vue {
  @Prop({
    default: () => {
      return false;
    }, required: false
  })
  withTitle: boolean

  @Prop({
    default: () => {
      return [];
    }, required: false
  })
  selectedScopes: string[]

  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  item?: MaintenanceAPI.Platforms.ScopeGroupItem;

  @Prop({
    default: () => {
      return null;
    }, required: false
  })
  path: string

  @Prop({
    default: () => {
      return false;
    }, required: false
  })
  editable: boolean

  @Prop({
    default: () => {
      return false;
    }, required: false
  })
  editing: boolean

  get showScopesTab() {
    if(this.editable) return true;
    return Object.keys(this.item.scopes).length > 0;
  }

  get showScopeGroupsTab() {
    if(this.item.path.split(':').length >= 3) return false;
    if(this.editable) return true;
    return Object.keys(this.item.scopeGroups).length > 0;
  }





  $refs!: {
    createForm: VForm
  }

  // Create Model
  createModel = {
    loading: false,
    key: null,
    title: null,
  }

  async create(bvModalEvt) {
    this.createModel.loading = true;
    bvModalEvt.preventDefault();

    if(!await this.$refs.createForm.validate()) {
      this.createModel.loading = false;
      return;
    }

    this.createModel.loading = false;

    this.$nextTick(() => {
      this.$bvModal.hide('create-scopegroup-model')
    })

    //this.$emit('setScopeGroup', $event)

    return {
      newItem: true,
      key: this.createModel.key,
      title: this.createModel.title,
      path: this.item.path,
      description: "",
      scopes: {},
      scopeGroups: {}
    }
  }

  cancel() {
    this.$refs.createForm.reset();
    this.$set(this, "createModel", {name: null})
  }
}

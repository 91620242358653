





































































import {Vue, Component, Prop} from 'vue-property-decorator'
import {BButton, BCard, BCardTitle, BNavItem, BTab, BTabs} from "bootstrap-vue";
import ScopeSelectorScopeGroup
  from "@/apps/maintenance-api/pages/components/ScopeSelector/components/ScopeSelectorScopeGroup.vue";

@Component({
  components: {
    ScopeSelectorScopeGroup,
    BCard,
    BCardTitle,
    BButton,
    BTabs,
    BTab,
    BNavItem
  }
})
export default class ScopeSelector extends Vue {
  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  item?: MaintenanceAPI.Platforms.Item;

  @Prop({
    default: () => {
      return false;
    }, required: false
  })
  withTitle: boolean

  @Prop({
    default: () => {
      return false;
    }, required: false
  })
  tabbed: boolean

  @Prop({
    default: () => {
      return false;
    }, required: false
  })
  multicard: boolean

  @Prop({
    default: () => {
      return false;
    }, required: false
  })
  editable: boolean

  @Prop({
    default: () => {
      return false;
    }, required: false
  })
  editing: boolean

  @Prop({
    default: () => {
      return [];
    }, required: false
  })
  selectedScopes: string[]
}
